
































































































import { Component, Vue, Watch,Prop,Mixins } from 'vue-property-decorator'
import imagePicker from './imagePicker.vue'
// import fImage from '@/v-components/ui-kit/image'
// import fRecord from '@/v-components/ui-kit/record'
import fAudio from '@/modules/common/components/g-audio.vue'
// import Loading from '@/v-components/common/loading'
import * as utils from '../../../filters/utils'
import uploadVideoQcloud from './upload-video-qcloud.vue'
import uploadAudioQcloud from './upload-audio-qcloud.vue'
import fVideo from '@/modules/common/components/hls-video.vue'
// import fVideo from '@/v-components/common/hls-video'
// import { Toast, Popup } from 'vant'
// import imagePicker from '@/components/imagePicker/imagePicker'

@Component({
  components: {
    imagePicker,
    uploadVideoQcloud,
    uploadAudioQcloud,
    fVideo,
    fAudio
    // SubjectWrap, 
    // MediaWrap, 
    // MediaUpload
  }
})

export default class CourseAudioClass extends  Vue {
[x: string]: any
  @Prop({default: () => {
    return {
      placeholder: '请输入...',
      textMaxLen: 3000,
      text: '',
      image: [],
      audio: [],
      video: []
    }
  }}) info!: any
  @Prop({default() { return true }}) isShowBtPadding!: Boolean
  @Prop({default: 9}) maxUpdateAudioCount!: Number
  imagesUrl : any = []
  text:any =  ''
  image:any =  []
  audio:any = []
  video:any = []
  isLoading:boolean = false
  isRecord:boolean = false
  maxFileSize:any= 300
  label:any = this.$route.query.label


  get textLen() {
    return utils.checkWordSize(this.refundText / 2) || 0
  }
  get emptyElmLen() {
    // justify-content:space-between 样式补空元素
    let empty = (this.image.length + 1) % 3
    return empty === 0 ? empty : 3 - empty
  }
  get imgDisable() {
    return this.image.length > 8
  }
  get audioDisable() {
    return this.audio.length >= this.maxUpdateAudioCount
  }
  get videoDisable() {
    return this.video.length > 0
  }
  created() {
    console.log(this.info, 'this.info');
    
    
    this.text = this.info.text
    this.image = this.info.image
    this.audio = this.info.audio
    this.video = this.info.video
    console.log(this.audio,'this.audiooooooo');
  }
  audioSelectFile(files:any) {
    // this.audioData = [];
  }

  urlData(data: any) {
    console.log(data,'dataaaaaaaaaaaaaa');
    
    // this.imagesUrl = []
    this.image.push(data.data.access_url)
    this.image = this.unique(this.image)
    // this.images = data
    // this.updateVal(this.imagesUrl)
    if(this.image.length >= 9) {
      // this.disabled = true
      this.$message('最多上传九张图片')
    }
    this.commitData()
  }
  unique (arr:any) {
    return Array.from(new Set(arr))
  }
  commitData() {
    this.$emit('commitData', {
      text: this.text,
      image: this.image,
      audio: this.audio,
      video: this.video
    })
  }
  // uploadImg() {
  //   // this.isRecord = false
  //   if(this.$refs.uploadImg && this.$refs.uploadImg.$refs) {
  //     this.$refs.uploadImg.$refs.imageUpload.handleClick()
  //     console.log(this.$refs.uploadImg.$refs.imageUpload.handleClick(),'this.$refs.uploadImg');
  //   }
    
    

  // }
  updateImg(data:any) {
    this.image = data
    this.commitData()
  }
  delItem(type:any, index:any) {
    this[type].splice(index, 1)
    this.commitData()
  }
  onRecord() {
    if (this.audioDisable) {
      this.$toast(`最多只能上传${this.maxUpdateAudioCount}条音频`)
      return
    }
    this.isRecord = true
  }
  // 录音的音频
  getRecord(data:any) {
    console.log(data,'this.audiooooooo');
    
    this.audio.push({
      url: data.data.access_url,
      tid: data.data.vid,
      duration: 30
    })
    this.isRecord = false
    this.commitData()
  }
  textInput() {
    this.commitData()
  }
  showToast() {
    // Toast({
    //   message: '上传中，请稍等',
    //   duration: 0,
    //   forbidClick: true
    // })
  }
  onClickUpload() {
    this.isRecord = false
  }
  onStart(item:any) {
    this.showToast()
  }
  onFinish(item:any) {
    console.log(item, '提交好了');
    
    this.video = []
    this.$nextTick(() => {
      const video = {
        file_id: item.video.fileId,
        url: item.video.url,
        token: item.video.token
      }
      this.video.push(video)
      // Toast.clear()
      this.commitData()
    })
  }
}
