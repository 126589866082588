

































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import SubjectMixin from '../mixins/subjuectMixin'
import MediaWrap from '../page/components/media-wrap.vue'
import SubjectWrap from '../page/components/subject-wrap.vue'
import draggable from 'vuedraggable'

@Component({
  components: {
    MediaWrap, 
    SubjectWrap,
    draggable
  },
  filters: {
    answerText(answers:any, options:any) {
      let temp:any = []
      answers && answers.forEach((item: any) => {
        const index = options.findIndex((v: { id: { toString: () => any } }) => item === v.id.toString())
        temp.push(options[index].selectTip)
      })
      return temp.join('')
    }
  }
})
export default class ClassWrap extends Mixins(SubjectMixin) {
[x: string]: any
  drag:boolean = false
  dragOptions:any =  {
    animation: 200,
    ghostClass: 'ghost',
    dragClass: 'sortable-drag',
    handle: '.handle'
  }
  list:any =  []
 
  
  created() {
    this.init()
  }
  init() {
    this.list = this.curSubjectInfo.detail.content.options
    if (this.answerShow) {
      this.curSubjectInfo.detail.is_correct = this.equals(this.curSubjectInfo.answer, this.curSubjectInfo.detail.correct_answer)
    }
  }
  change() {
    let answers:any = []
    this.list.forEach((item: { id: any }) => {
      answers.push(item.id)
    })
    const curAnswer = this.makeAnswer(answers)
    this.saveAnswer(curAnswer)
  }
  makeAnswer(value: any) {
    return {
      material_id: this.curSubjectInfo.childId,
      question_type: this.subType,
      value
    }
  }
}
