

















import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import SubjectWrap from '../page/components/subject-wrap.vue'
import SubjectMixin from '../mixins/subjuectMixin'
// import SplitScreen from '../split-screen'
import BlankSubject from './blank.vue'
import NormalSubject from './normal.vue'
import SpokenSubject from './spoken.vue'
import SelectBlankSubject from './select-blank.vue'
import SortSubject from './sort.vue'
import ListeningSubject from './listening.vue'

@Component({
  components: {
    SubjectWrap,
    // SplitScreen,
    BlankSubject,
    NormalSubject,
    SpokenSubject,
    SelectBlankSubject,
    SortSubject,
    ListeningSubject
  }
})
export default class ClassWrap extends Mixins(SubjectMixin) {
  // @Prop() id: any
  created() {
    
  }
  mapComponentType(type:any) {
    const map:any = {
      radio: 'normal-subject',
      judge: 'normal-subject',
      checkbox: 'normal-subject',
      singleblank: 'blank-subject',
      mulitblank: 'blank-subject',
      englishspoken: 'spoken-subject',
      mandarin: 'spoken-subject',
      optionblank: 'select-blank-subject',
      sort: 'sort-subject',
      listening: 'listening-subject'
    }
    return map[type]
  }
}
