



























import { Component, Vue, Watch,Prop,Mixins } from 'vue-property-decorator'
import qcloudUploadFile from './qcloud-upload-file-flow.vue';
// import * as api from '@/api/materia';

@Component({
  components: {
    qcloudUploadFile,
  }
})

export default class CourseAudioClass extends  Vue {
  @Prop() accept!: String
  @Prop() source!: String
  @Prop() multiple!: Boolean
  @Prop() label!: any
  @Prop() maxFileSize!: any
  @Prop() onSelectFile!: any
  @Prop() onFinish!: any
  @Prop() onSuccess!: any
  // @Prop() onStart!: any
  // @Prop() onError!: any

  selectFiles:any = []
  cancleFileIndex:any = []
  fileArr:any = []
  uploading:boolean = false
  currentIndex:any = 0
  currentItem:any = ''
  // toCancleFile(item, index) {
  //   this.cancleFileIndex.push(index);
  //   this.onFileError(index, null, '取消上传');
  // },
  toMaterial() {
    this.$router.push({ name: 'materialAudio' })
  }
  onFileSelect(files:any) {
    this.fileArr = [];
    this.onSelectFile(files);
    this.selectFiles = files;
    // this.errorMag = '';
    // this.errorNum = 0;
    // this.successNum = 0;
  }
  onFileUpdate(index:any, file:any, size:any) {
    // this.onStart();
    let uploadData = {
      'uploading': true,
      'finish': false,
      'size': (file.size / (1024 * 1024)).toFixed(2),
      'name': file.name,
      'style': { 'width': '0%' },
      'percent': '等待中',
      'url': '',
      'error': ''
    };
    this.fileArr.push(uploadData);
  }
  onFileStart(index:any, file:any) {
    // this.fileArr[index].style.width = '0%';
    this.currentIndex = index;
    this.currentItem = this.fileArr[index];
    this.uploading = true;
  }
  onFileProgress(index:any, file:any, percent:any) {
    if (this.fileArr[index]) {
      this.fileArr[index].percent = percent + '%';
      this.fileArr[index].style.width = percent + '%';
      this.currentItem = this.fileArr[index];
    }
  }
  onFileError(index:any, file:any, message:any) {
    if (this.fileArr[index]) {
      this.onFileReset(index, message);
    }
  }
  onFileFinish(index:any, file:any, data:any) {
    console.log(data);
    if (this.fileArr[index]) {
      this.fileArr[index].percent = '已完成';
      this.fileArr[index].style.width = '100%';
      // this.fileArr[index].upload = this.fileArr[index].size;
      this.currentItem = this.fileArr[index];
      this.fileArr[index].uploading = false;
    }
    // if (this.fileArr[index]) {
    //   let dataInfo = {
    //     fileName: file.name,
    //     url: data.data.source_url,
    //     size: this.fileArr[index].size,
    //   }
    //   this.fileArr[index].percent = '已完成';
    //   this.fileArr[index].style.width = '100%';
    //   this.fileArr[index].finish = true;
    //   this.currentItem = this.fileArr[index];
    //   this.saveAudio(dataInfo, index);
    //   // this.onSuccess(dataInfo);
    // }
  }
  onFileReset(index:any, error:any) {
    // this.fileArr[index].size = 0;
    this.fileArr[index].url = ''
    this.fileArr[index].percent = '';
    this.fileArr[index].style.width = 0;
    this.fileArr[index].uploading = false;
    this.fileArr[index].finish = true;
    this.fileArr[index].error = error || '上传失败，请保存其他音频之后重试';
  }
  onAllFileFinish() {
    console.log('onAllFileFinish');
    this.onFinish();
    this.uploading = false;
  }
  saveAudio(info:any, index:any) {
    // api.addMaterial({
    //   type: 'audio',
    //   sign: 'manage',
    //   tag_id: this.label || undefined,
    //   content: [{
    //     'info': { file_name: info.fileName, size: info.size, url: info.url }
    //   }]
    // }).then((res:any) => {
    //   this.fileArr[index].percent = '自动保存成功';
    //   this.fileArr[index].uploading = false;
    // }, (rej:any) => {
    //   this.$message.error(info.fileName + '保存失败');
    //   this.onFileReset(index, '保存失败, 请重新上传')
    // });
  }
  audioData(data:any) {
    this.$emit('getRecord',data)
  }
}
