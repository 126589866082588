


























import { Component, Vue, Watch, Mixins, Prop } from 'vue-property-decorator'
import fImage from './apply/image.vue'
import fAudio from '@/modules/common/components/g-audio.vue'
import fVideo from '@/modules/common/components/hls-video.vue'
import uuidv4 from 'uuid/v4'

  @Component({
    components: {
      fImage,
      fAudio,
      fVideo
    }
  })
export default class CourseAudioClass extends Vue {
  @Prop({default() { return {} }}) info!: any
  @Prop({default: false}) isOptMedia!: Boolean
  @Prop({default: 345}) imgWidth!: any
  @Prop({default: '17.4rem'}) audioWidth!: String
  @Prop({default: '13.9rem'}) videoHeigth!: String
  @Prop({default: 'style1'}) audioType!: String
  get imageData() {
    let image:any = []
    if (typeof this.info.image === 'string') {
      return [this.info.image]
    }
    const imageArr = this.info.image
    if (imageArr && imageArr.length) {
      imageArr.forEach((item:any) => {
        if (item.url) {
          image.push(item.url)
        } else {
          image.push(item)
        }
      })
    }
    return image
  }
  get videoData() {
    let videos = this.info.video
    if (videos && videos.length) {
      videos.forEach((item:any) => {
        item.tid = uuidv4()
        item.playData = {
          fileId: item.file_id,
          token: item.token ? item.token : '',
          videoUrl: item.token ? '' : (item.video_url || item.url)
        }
      })
    }
    return videos
  }
  get audioData() {
    console.log(this.info.audio, 'this.info.audio');
    
    return this.info.audio
  }
} 
