



















import { Component, Vue, Watch,Prop } from 'vue-property-decorator'
// import {imageOperate} from '@/filters/image'
// import {previewImg} from '@/filters/env/duanshu'

@Component({
  components: {
  }
})

export default class CourseAudioClass extends Vue {
    @Prop({default: '//oss-web.duanshu.com/ds_projects/ds_app_tech/static/img/global/img-error.png'}) url!: any
    @Prop({default: true}) isPreview!: Boolean // 是否支持预览
    @Prop({default: true}) isLazy!: Boolean // 是否懒加载
    @Prop({default: () => { return [] }}) images!: Array<any> // 预览的数组
    @Prop({default: 320}) width!: any
    @Prop({default: 180}) height!: any
    @Prop({default: 3}) borderRadius!: any
    @Prop({default: false}) showLoading!: Boolean
    @Prop() imageStyle!: String
    @Prop() wrapStyle!: String
    @Prop({default: false}) isWework!: Boolean
    loading:any =  false
    get imgWrapStyle() {
      return this.wrapStyle || `width: 406px; height: 228px;position:relative`
    }
    get imgStyle() {
      return this.imageStyle || `width: 406px; height: 228px;border-radius: ${this.borderRadius / 14}rem;object-fit: contain`
    }
    created() {
      this.loading = this.showLoading
    }
    loaded() {
      this.loading = false
      this.$emit('loaded')
    }
    // 预览
    // previewImg(url:any) {
    //   if (!this.isPreview) return
    //   if (this.isWework) {
    //     previewImg(url, this.images)
    //     return
    //   }
    //   if (!this.isPreview) return
    //   if (this.images.length) {
    //     imageOperate.preview(url, this.images)
    //   } else {
    //     imageOperate.preview(url)
    //   }
    // }
}
