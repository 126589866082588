import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import commonMixin from './commonMixin'
import { eventBus } from '../filters/event-bus'

@Component
export default class ClassWrap extends Mixins(commonMixin) {
  get answerShow() {
    return this.renderType === 'analysis' || (this.examType === 'practice' && this.curSubjectInfo.isAnswer)
  }
  get curAnswer() {
    console.log(this.$store.state.exam.curAnswer, '答案')
    return this.$store.state.exam.curAnswer
  }

  equals(arr1:any, arr2:any) {
    if (!arr1 || !arr2) return false
    if (arr1.length !== arr2.length) return false
    for (var i = 0, l = arr1.length; i < l; i++) {
      if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
        if (!this.equals(arr1[i], arr2[i])) return false
      } else if (arr1[i] !== arr2[i]) {
        return false
      }
    }
    return true
  }
  // 选择题、判断题、选词填空 匹配正确答案
  matchAnswer() {
    this.curSubjectInfo.detail.is_correct = this.equals(this.curSubjectInfo.answer, this.curSubjectInfo.detail.correct_answer)
    this.curSubjectInfo.detail.content.options.map((optItem: { id: string; status: string; is_correct: any }) => {
      // 做答题数据兼容 选项数据全部转换成字符串
      const answers = this.curSubjectInfo.answer && this.curSubjectInfo.answer.map((id: any) => String(id))
      optItem.id = String(optItem.id)
      // 处理答案匹配
      if (answers && answers.includes(optItem.id)) {
        // eslint-disable-next-line no-mixed-operators
        if (this.renderType === 'exam' && this.examType !== 'practice' || (this.examType === 'practice' && !this.curSubjectInfo.isAnswer)) {
          // optItem.active = true
          optItem.status = 'active'
        } else if (optItem.is_correct) {
          // optItem.isAnswerCorrect = true
          optItem.status = 'correct'
        } else {
          // optItem.error = true
          optItem.status = 'error'
        }
      }
    })
  }
  // 保存答案
  saveAnswer(answer: any) {
    eventBus.$emit('index.setCurAnswer', answer)
  }
}