





























import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import IndexMixin from '../mixins/indexMixin'
// import * as utils from '@/filters/utils'
import { getAnswerDetail, getExamDetail, startExam, getSubject, submitRecord, endExam } from '@/modules/exam/api/index'
import * as utils from '../filters/utils'
import headerInfo from './components/header.vue'
import subjectList from './components/subject-list.vue'
import bottomInfo from './components/bottom.vue'
import { eventBus } from '@/modules/exam/filters/event-bus'

@Component({
  components: {
    headerInfo,
    subjectList,
    bottomInfo
  }
})
export default class ClassWrap extends Mixins(IndexMixin) {
  // @Prop() id!: any
  isLoading: boolean = false
  isSubLoading: boolean = false
  errorObj: any = null
  answerCardDetail: any = {}
  examDetail: any = {}
  // curAnswer: any = []
  curAnswer: any = null // 当前题目答案的暂存区

  created() {
    this.$store.dispatch('resetStateData')
    this.initEvent()
    this.initPage()
  }

  initEvent() {
    eventBus.$off()
    // 切换题目
    eventBus.$on('index.changeSubject', (subject: any) => {
      this.isSubLoading = true
      
      if (this.curAnswer && this.curAnswer.value) {
        this.submitRecord()
      }
      this.getSubject(subject.id)
    })
    // 点击交卷，先提交答题记录
    eventBus.$on('index.submitExam', (e: any) => {
      if (this.curAnswer && this.curAnswer.value) {
        this.submitRecord().then(res => {
          eventBus.$emit('header.showDialog', this.answerCardDetail) // 确认交卷的弹窗
        })
      } else {
        eventBus.$emit('header.showDialog', this.answerCardDetail) // 确认交卷的弹窗
      }
    })
    // 考试时间到，自动交卷
    eventBus.$on('index.autoSubmitExam', (e: any) => {
      if (this.isSubLoading) return
      this.isSubLoading = true
      
      if (this.curAnswer) {
        this.submitRecord().then(() => {
          this.submitExam()
        })
      } else {
        this.submitExam()
      }
    })
    // 当前题目的答案
    eventBus.$on('index.setCurAnswer', (e: any) => {
      this.curAnswer = e
    })
    // 练习模式提交答案
    eventBus.$on('index.practice.submitRecord', (e: any) => {
      if (this.curAnswer && this.curAnswer.value) {
        this.submitRecord().then((res: any) => {
          // res.tid = uuidv4()
          this.$store.commit('SET_CUR_SUBJECY', JSON.parse(JSON.stringify(res)))
        }).catch(() => {
          // this.curSubjectInfo.tid = uuidv4()
          this.$store.commit('SET_CUR_SUBJECY', JSON.parse(JSON.stringify(this.curSubjectInfo)))
        })
      }
    })
    // 考试模式提交答案
    eventBus.$on('index.exam.submitRecord', (subject: any) => {
      if (this.curAnswer && this.curAnswer.value) {
        this.submitRecord()
      }
      this.$store.commit('SET_CUR_SUBJECY', subject)
    })
  }

  async initPage() {
    // this.postClassTrack()
    await this.startExam()
    Promise.all([this.getExamDetail(), this.getAnswerList()]).then(res => {
      this.answerCardDetail.answer_card.forEach((item: any) => {
        item.childId = item.id
      })
      
      this.$store.commit('SET_CARD_LIST', this.answerCardDetail.answer_card)
      
      
      this.getSubject(this.curSubId || this.answerCardDetail.answer_card[0].id) // 获取第一题
    }).catch(e => {
      this.isLoading = false
      this.errorObj = e
    })
  }

  // 获取试题详情
  async getExamDetail() {
    return getExamDetail( this.id ).then(res => {
      this.examDetail = res
      utils.windowTitle(this.examDetail.name)
      this.$store.commit('SET_EXAM_DETAIL', JSON.parse(JSON.stringify(this.examDetail)))
    }).catch(e => {
      return Promise.reject(e.errorObj)
    })
  }

  // 获取答题卡详情
  getAnswerList() {
    return getAnswerDetail(this.id).then((res: any) => {
      if (res && res.answer_card && res.answer_card.length > 0) {
        res.answer_card.map((item:any, index:any) => {
          item.childId = item.id
          if (index === 0) {
            item.seq = item.type === 'rich_text' ? 0 : 1
          } else {
            item.seq = item.type === 'rich_text' ? res.answer_card[index - 1].seq : res.answer_card[index - 1].seq + 1
          }
        })
        this.answerCardDetail = res
      } else {
        return Promise.reject({
          imgName: 'box-empty',
          m: '暂时没有题目',
          btns: [{
            text: '重新加载',
            action: 'reload'
          }, {
            text: '返回首页',
            action: 'toHome',
            cls: 'link'
          }]
        })
      }
    }).catch((e: any) => {
      return Promise.reject(e.errorObj)
    })
  }

  // 获取题目
  getSubject(questionId: any) {
    getSubject(questionId).then(res => {
      if( res.answer && res.answer.length > 0 ){
        res.content.options.forEach((r: any) => {
          res.answer.forEach((r1: any) => {
            if(r.id == r1){
              if(r.is_correct == false){
                r.status = 'error'
              }else{
                r.status = 'correct'
              }
            }
          });
        });
      }
      this.handleSubject(res, this.answerCardDetail.answer_card)
      this.isLoading = false
      this.isSubLoading = false
    }).catch(e => {
      this.isLoading = false
      this.isSubLoading = false
      this.errorObj = e.errorObj
    })
  }

  // 开始考试
  startExam() {
    if (this.renderType === 'analysis') {
      return true
    } else {
      return startExam(this.id).catch(e => {
        if (e.response.data.error === '40202') { // 未订阅且不是试学
          this.toContentDetail()
        } else if (e.errorObj) {
          this.isLoading = false
          this.errorObj = e.errorObj
        }
        return Promise.reject(e.errorObj)
      })
    }
  }

  // 结束考试
  endExam() {
    endExam(this.id).then((res: any) => {
      // 跳转到成绩单页面
      this.isSubLoading = false
      if (this.examDetail.has_subscribed) {
        this.$router.replace({
          name: 'examResult',
          params: {id: this.id},
          query: {
            contentId: this.contentId,
            contentType: this.contentType
          }
        })
      } else {
        this.toContentDetail()
      }
    }).catch((e: any) => {
      // this.isSubLoading = false
      // let {errorObj} = e
      // if (errorObj) this.$toast(errorObj.toastMsg)
    })
  }

  // 提交答题记录
  async submitRecord() {
    let temp = []
    temp.push(this.curAnswer)
    const index = this.examList.findIndex((v: any) => v.childId === this.curAnswer.material_id)
    const subjectInfo = this.examList[index]
    // let data: any = {
    //   id: this.id,
    //   answers: temp
    // }
    return submitRecord(this.id, { answers: temp }).then((res: any) => {
      console.log('提交记录成功后');
      // 提交答题记录成功后，把用户答案放到题目的数据中，更新题目列表
      subjectInfo.answer = this.curAnswer.value
      subjectInfo.isAnswer = true
      this.curAnswer = null // 清空当前题目的答案
      this.$store.dispatch('setSubjectItem', subjectInfo)
      
      return this.getAnswerList().then(() => { // 更新答题卡
        return subjectInfo
      }).catch((e: any) => {
        // if (e) this.$toast(e.toastMsg)
        return Promise.reject()
      })
    }).catch((e: any) => {
      console.log('提交记录失败后');
      console.log(subjectInfo, 'subjectInfo');
      
      this.curAnswer = null // 清空当前题目的答案
      this.$store.dispatch('resetStateData')
      this.resetSubject(subjectInfo) // 重置当前题目的数据
      // this.$toast('出错了，上题答案没保存请返回上一题重新作答')
      return Promise.reject(e.errorObj)
    })
  }

  // 交卷
  async submitExam() {
    this.isSubLoading = true
    this.endExam()
  }

  toContentDetail() {
    let name = ''
    switch (this.contentType) {
      case 'provingground': // 试炼场
        name = 'Briprovingground'
        break
      case 'trainingcampclass': // 训练营
        name = 'Britrainingcampclass'
        break
      case 'course': // 课程
        name = 'Bricourse'
        break
    }
    this.$router.replace({name, params: { id: this.contentId }})
  }
}
