





















import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import MediaWrap from './media-wrap.vue'


@Component({
  components: {
    MediaWrap
  }
})
export default class ClassWrap extends Vue {
  @Prop() subjectinfo!: any
  difficultyText:any =  {
    easy: '简单',
    middle: '中等',
    hard: '困难'
  }
  get videoData() {
    return this.subjectinfo.attachment.video || []
  }
  get audioData() {
    return this.subjectinfo.attachment.audio || []
  }
  get isShow() {
    return this.subjectinfo.analysis || this.videoData.length || this.audioData.length
  }
  get accuracy() {
    return this.subjectinfo.accuracy ? this.subjectinfo.accuracy * 100 : 0
  }
}
