
































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import MediaWrap from '../page/components/media-wrap.vue'
import SubjectWrap from '../page/components/subject-wrap.vue'
import MediaUpload from '../page/components/apply/media-upload.vue'
import SubjectMixin from '../mixins/subjuectMixin'

@Component({
  components: {
    SubjectWrap, 
    MediaWrap, 
    MediaUpload
  }
})
export default class ClassWrap extends Mixins(SubjectMixin) {
  // @Prop() id: any
  answerInfo:any = {
    placeholder: '请输入...',
    textMaxLen: 1000,
    text: '',
    image: [],
    audio: [],
    video: []
  }
  get isShowRemark() {
    const remark = this.curSubjectInfo.detail.remark
    return remark && Object.keys(remark).length
  }
  created() {
    this.init()
  }
  init() {
    if (this.curSubjectInfo.isAnswer) {
      this.answerInfo = Object.assign(this.answerInfo, this.curSubjectInfo.answer)
    }
  }
  // 检查答案
  checkNeedSave(value:any) {
    return value.text !== '' || value.image.length || value.audio.length || value.video.length
  }
  // 构造答案的数据结构
  makeAnswer(value:any) {
    if (this.checkNeedSave(value)) {
      return {
        material_id: this.curSubjectInfo.childId,
        question_type: this.subType,
        value
      }
    } else {
      return null
    }
  }
  getAnswerData(value:any) {
    const curAnswer = this.makeAnswer(value)
    this.saveAnswer(curAnswer)
  }

}
