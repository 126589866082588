









import { Component, Vue, Watch,Prop,Mixins } from 'vue-property-decorator'
import fileSelect from './file-select.vue';
import { getTime, getQcloud } from '@/modules/common/components/info-form/components/filters/images';
// import { http } from '@/filters/http';
import * as api from '@/modules/course/api/signature';
import * as utils from '../../../filters/utils';
import {getCosSignature} from '@/modules/course/api/signature';

@Component({
  components: {
    fileSelect,
  }
})

export default class CourseAudioClass extends  Vue {
  @Prop() accept!: String
  @Prop({default() { return 'files' }}) path!: String
  @Prop() maxFile!: Number
  @Prop({default() { return 5; }}) maxFileSize!: Number
  @Prop({default() { return [] }}) cancleFileIndex!: Array<any>
  @Prop({default() { return 0; }}) currentFileLength!: Number
  @Prop({default() { return false; }}) multiple!: Boolean
  @Prop() onFileSelect!: any
  @Prop() onAllFileFinish!: any
  @Prop() onFileUpdate!: any
  @Prop() onFileStart!: any
  @Prop() onFileFinish!: any
  @Prop() onFileError!: any
  @Prop() onFileProgress!: any

 
  fileLength:any = 0
  uploadFiles:any = []
  // guid:any = JSON.parse((localStorage.getItem('loginData') as string)).shop.id
  guid:any=  localStorage.getItem('loginData')
  errorMap:any = {
    'slice upload file error': '文件解析失败，请清除浏览器缓存后重新上传',
    'not find file in cos': '文件缓存查找失败，请清除浏览器缓存后重新上传',
  }
  cos:any

  created() {
    // this.fileLength = this.currentFileLength; // 暂无地方使用到
    this.initUploader();
  }
  // watch: {
  //   currentFileLength(val) {
  //     this.fileLength = val;
  //   }
  // },
  initUploader() {
    this.cos = new window.CosCloud({
      appid: getQcloud('appid'),
      bucket: getQcloud('bucket_name'),
      region: getQcloud('district'),
      getAppSign: (callback:any) => {

        api.getCosSignature().then(res => {
          if (res.sign) {
            callback(res.sign);
          } else {
            this.$message.error('签名访问错误');
          }
        }, rej => {
          this.$message.error(rej.message);
        });
      },
    })
  }
  fileSelectHandle(files:any) {
    if (!files) {
      return;
    }
    if (files.length > this.maxFile) {
    // if (this.fileLength + files.length > this.maxFile) {
      this.$message.error('最多支持上传' + this.maxFile + '个文件');
      return;
    }
    this.uploadFiles = files;
    this.onFileSelect(files);
    this.toStartUpload(files);
  }
  toStartUpload(files:any) {
    console.log(files,'filesfilesfilesfiles');
    
    for (let i = 0; i < files.length; i++) {
      this.fileLength++;
      let fileSize = '';
      if (files[i].size > (1024 * 1024 * 0.1)) {
        fileSize = (files[i].size / 1024 / 1024).toFixed(1) + 'M';
      } else {
        fileSize = Math.round(files[i].size / 1024) + 'k';
      }
      this.onFileUpdate(i, files[i], fileSize);
    }
    console.log(files[0],'files[0]files[0]files[0]');
    
    this.uploadFile(0, files[0]);
  }
  uploadFile(i:any, file:any) {
    let next = i + 1;

    if (this.cancleFileIndex.includes(i)) {
      this.onFileError(i, file, '取消上传');
      this.startNext(next);
      return
    }
    const format = file.name.split('.').pop() ? ( '.' + file.name.split('.').pop() ) : '';
    const name = [new Date().getTime(), utils.randomNum( 6 )].join('_') + format;
    const pattern = new RegExp("[#^:\,<>/……——，`]");

    this.onFileStart(i, file, '开始');

    if (pattern.test(file.name)) {
      this.onFileError(i, file, '文件名请勿包含特殊字符[#^:\,<>/……——，`]');
      this.startNext(next);
    } else if ((file.size / 1024 / 1024) > this.maxFileSize) {
      this.onFileError(i, file, '文件不能超过' + this.maxFileSize + 'M');
      console.log('next: ', next);
      this.startNext(next);
    } else {
      console.log('看看能不能你进来。。。。。。。。');
      console.log(this.cos.uploadFile, 'this.costhis.cos');
      
      // 不分片上传: cos.uploadFile(successCallBack, errorCallBack, progressCallBack, bucket, path, file, insertOnly);
      // 分片上传: cos.sliceUploadFile(successCallBack, errorCallBack, progressCallBack, bucket, path, file, insertOnly);
      this.cos.uploadFile(
        (data:any) => {
          
          if (data && data.data && data.data.url) {
            console.log('uploadFile data: ', data);
            this.$emit('audioData',data)
            this.onFileFinish(i, file, data);
            this.startNext(next);
          } else {
            this.onFileError(i, file, '上传失败');
            this.startNext(next);
          }
        },
        (error:any) => {
          console.log('uploadFile error: ', error);
          this.onFileError(i, file, this.errorMap[error.message] || error);
          this.startNext(next);
        },
        (progress:any) => {
          console.log('uploadFile progress: ', progress);
          this.onFileProgress(i, file, Math.ceil(progress * 100));
        },
        getQcloud('bucket_name'),
        getTime() + '/' + this.guid + '/' + getQcloud('client_id') + '/' + this.path + '/' + name, file, 1
      );
    }
  }
  startNext(next:any) {
    if (next < this.uploadFiles.length) {
      this.uploadFile(next, this.uploadFiles[next]);
    } else {
      // this.$refs.fileSelect.clearFile();
      // this.onAllFileFinish();
    }
  }
}
