








// import TcVod from 'vod-js-sdk-v6'
import { Component, Vue, Watch,Prop,Mixins } from 'vue-property-decorator'
import fileSelect from './file-select.vue'
import * as utils from '../../../filters/utils'
import { getQcloudVodSignature } from '@/modules/course/api/signature'


@Component({
  components: {
    fileSelect
    
  }
})

export default class CourseAudioClass extends  Vue {
  @Prop() accept!: String
  @Prop({default() { return false }}) multiple!: Boolean
  @Prop({default() { return 512 }}) maxFileSize!: Number
  uploadFiles:any= []
  resultFiles:any= []
  tcVod:any= ''
  currentIndex:any= 0
  uploader:any= ''
  signature:any

  created() {
    utils.loadJsPromise('vod-js-sdk-v6').then((res:any) => {
      // eslint-disable-next-line new-cap
      this.tcVod = new window.TcVod.default({
        getSignature: () => {
          return getQcloudVodSignature().then((res:any) => {
            console.log(res)
            if (res && res.signature) {
              this.signature = res.signature
              return res.signature
            } else {
              // this.$toast('上传签名访问错误')
              return false
            }
          }).catch((e:any) => {
            // this.$toast(e.errorObj && e.errorObj.toastMsg)
            return false
          })
        }
      })
    }).catch((e:any) => {
      // this.$toast('加载文件上传插件失败')
    })
  }
  beforeDestroy() {
    if (this.uploader) {
      this.uploader.cancel()
    }
  }
  onFileSelect(files:any) {
    this.uploadFiles = Array.from(files)
    if (this.uploadFiles.length > 10) {
      console.log('zheli');
      
      // this.$toast('至多上传10个文件')
      return
    }
    console.log(this.uploadFiles, 'this.uploadFiles');
    
    this.$emit('onFileSelect', this.uploadFiles)
    this.resultFiles = this.uploadFiles.map((item:any, index:any) => {
      return {
        index,
        name: '',
        progress: '0%',
        status: 'wait',
        video: ''
      }
    })
    this.upload(this.currentIndex, this.uploadFiles[0])
    (this.$refs.fileSelect as any).clearFile()
  }
  upload(curIndex:any, file:any): any {
    let next = curIndex + 1
    this.resultFiles[curIndex].name = file.name
    this.resultFiles[curIndex].status = 'start'
    this.$emit('onStart', this.resultFiles[curIndex])

    const format = file.name.split('.').pop() ? ('.' + file.name.split('.').pop()) : ''
    const name = [new Date().getTime(), utils.randomNum(6)].join('_') + format
    const pattern = new RegExp('[#^:\\,<>/……——，`]')
    const videoSize: number = parseFloat((file.size / (1024 * 1024)).toFixed(2))   // 判断上传视频大小
    file.msize = videoSize

    if (pattern.test(file.name)) {
      this.resultFiles[curIndex].error = '文件名请勿包含特殊字符[ #^:\\,<>/……——，\' ]'
      this.$emit('onError', this.resultFiles[curIndex])
      this.startNext(next)
    } else if (videoSize > this.maxFileSize) {
      this.resultFiles[curIndex].error = `请将视频文件压缩到${this.maxFileSize}M 以内`
      this.$emit('onError', this.resultFiles[curIndex])
      this.startNext(next)
    } else {
      this.uploader = this.tcVod.upload({
        mediaFile: file, // 媒体文件（视频或音频或图片），类型为 File
        mediaName: name
      })
      this.uploader.on('media_progress', (info:any) => {
        console.log('media_progress: ', info) // 进度
        this.resultFiles[curIndex].progress = info.percent * 100 + '%'
        this.$emit('onProgress', this.resultFiles[curIndex])
      })
      this.uploader.on('media_upload', (info:any) => {
        console.log('media_upload: ', info) // 上传成功
        this.resultFiles[curIndex].status = 'finish'
        this.resultFiles[curIndex].progress = '100%'
      })
      // 回调结果说明
      // type doneResult = {
      //   fileId: string,
      //   video: {
      //     url: string
      //   },
      //   cover: {
      //     url: string
      //   }
      // }
      this.uploader.done().then((doneResult:any) => {
        // deal with doneResult
        console.log('doneResult: ', doneResult)
        this.resultFiles[curIndex].status = 'success'
        this.resultFiles[curIndex].video = {
          fileId: doneResult.fileId,
          url: doneResult.video.url,
          token:doneResult.verify_content
        }
        this.$emit('onFinish', this.resultFiles[curIndex])
        this.startNext(next)
      }).catch((err:any) => {
        // deal with error
        console.log('done err: ', err)
        this.resultFiles[curIndex].status = 'error'
        this.resultFiles[curIndex].error = err
        this.$emit('onError', this.resultFiles[curIndex])
        this.startNext(next)
      })
    }
  }
  startNext(next:any) {
    console.log('next: ', next)
    console.log('next < this.uploadFiles.length: ', next < this.uploadFiles.length)
    if (next < this.uploadFiles.length) {
      this.upload(next, this.uploadFiles[next])
    } else {
      this.$emit('onFinishAll', this.resultFiles)
    }
  }
}

