




























































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import MediaWrap from '../page/components/media-wrap.vue'
import SubjectWrap from '../page/components/subject-wrap.vue'
import SubjectMixin from '../mixins/subjuectMixin'
import fAudio from '@/modules/common/components/g-audio.vue'
import uploadAudioQcloud from '../page/components/apply/upload-audio-qcloud.vue'

@Component({
  components: {
    SubjectWrap, 
    MediaWrap, 
    uploadAudioQcloud,
    // fRecord, 
    fAudio, 
    // 'v-chart': ECharts, 
    // [Popup.name]: Popup
  }
})
export default class ClassWrap extends Mixins(SubjectMixin) {
  answerAudio:any= null
  followText:any= ''
  chartData: any={}
  showRecord:boolean= false
  maxFileSize:any= 300
  created() {
    this.init()
  }
  mounted() {
    if (!this.answerShow) {
      this.showRecord = true
    }
  }
  audioSelectFile(files:any) {
    // this.audioData = [];
  }
  init() {
    if (this.curSubjectInfo.isAnswer) {
      this.answerAudio = {
        url: this.curSubjectInfo.answer.url,
        tid: this.curSubjectInfo.answer.media_id,
        duration: this.curSubjectInfo.answer.duration
      }
    }
    if (this.answerShow && this.curSubjectInfo.isAnswer) {
      this.followText = this.dealFollowText()
      // this.drawVoiceEchart()
    }
  }
  getRecord(data:any) {
    console.log(data, 'dataqqqqqqqqqqqq');
    
    this.answerAudio = {
      url: data.data.access_url,
      serverId: data.data.vid,
      duration: 30
    }
    // 提交答案
    const curAnswer = this.makeAnswer(this.answerAudio)
    this.saveAnswer(curAnswer)
  }
  makeAnswer(data:any) {
    return {
      material_id: this.curSubjectInfo.childId,
      question_type: this.subType,
      value: {
        url: data.url,
        duration: data.duration,
        media_id: data.serverId
      }
    }
  }
  dealFollowText() {
    let followText = this.curSubjectInfo.detail.correct_answer[0]
    let words = this.dealWords(this.curSubjectInfo.detail.extra_data.Words)
    // 这边跟原文字进行比对
    let template = (val:any) => `<span style="color:#FF5A4E">${val}</span>`
    let mandarinInfo = { index: 0, str: followText }
    let englishArr = followText.split(' ')
    words.forEach((wItem:any, index:any) => {
      // 处理判定条件
      if (wItem.PronAccuracy < 80) {
        // 进行替换针对错误的
        if (this.subType === 'mandarin') {
          let errorIndex = mandarinInfo.str.indexOf(wItem.Word)
          if (errorIndex !== -1) {
            let realIndex = errorIndex + mandarinInfo.index
            // 处理数据
            const preText = followText.substring(0, realIndex)
            const afterText = followText.substr(realIndex + 1)
            followText = `${preText}${template(wItem.Word)}${afterText}`
            // 更新中文比对信息
            mandarinInfo.index = realIndex
            mandarinInfo.str = followText.substr(realIndex)
          }
        } else {
          const realWord = englishArr[index]
          if (realWord) englishArr[index] = template(realWord)
        }
      }
      // 进行拼接
      if ((index === words.length - 1) && this.subType === 'englishspoken') {
        followText = englishArr.join(' ')
      }
    })
    // 进行中文跟读的标点符号转换
    const signs = ['，', '。', '！', '!', '?', '？']
    let getSign = (index:any) => `<span>${signs[index]}</span>`
    signs.forEach((sign, index) => {
      let regStr = '\\' + sign
      let reg = new RegExp(regStr, 'g')
      followText = followText.replace(reg, getSign(index))
    })
    // 进行整体转换
    return followText
  }
  dealWords (words:any) {
    return words.filter((item:any) => {
      return (item.Word && item.Word !== '*')
    })
  }
  // drawVoiceEchart () {
  //   this.$nextTick(() => {
  //     const { VOICE_RANDAR } = EchartConfig
  //     const extraData = this.curSubjectInfo.detail.extra_data
  //     const chartInfo:any = {
  //       PronAccuracy: extraData.PronAccuracy,
  //       PronFluency: extraData.PronFluency,
  //       PronCompletion: extraData.PronCompletion
  //     }
  //     let options = JSON.parse(JSON.stringify(VOICE_RANDAR))
  //     let indicators = options.radar[0].indicator
  //     const maping:any = {
  //       PronAccuracy: '准确度',
  //       PronFluency: '流利度',
  //       PronCompletion: '完整度'
  //     }
  //     for (let key in chartInfo) {
  //       const chartVal = chartInfo[key]
  //       const mapKey = maping[key]
  //       if (mapKey) {
  //         let curText = mapKey
  //         if (chartVal > 0) {
  //           curText = `${mapKey}\n ${chartVal}%`
  //         }
  //         // 索引点
  //         indicators.push({ text: curText, max: 100 })
  //         // 存放值
  //         if (chartVal > 0) options.series[0].data[0].value.push(chartVal)
  //       }
  //     }
  //     // 初始化图
  //     this.chartData = options
  //   })
  // }
}
