






















import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import SubjectMixin from '../mixins/subjuectMixin'
import MediaWrap from '../page/components/media-wrap.vue'
import SubjectWrap from '../page/components/subject-wrap.vue'

@Component({
  components: {
    MediaWrap, 
    SubjectWrap
  }
})
export default class ClassWrap extends Mixins(SubjectMixin) {
[x: string]: any
  blankStyle:any =  'underline'
  newTitle:any =  ''
  answerArr:any =  []
  inputElArr:any = []
  inputTimer:any = null
  textBoxArr:any =[]
  answerVal:any = []
  matchArr:any
  get detail() {
    return this.curSubjectInfo.detail.content
  }
  created() {
    this.init()
  }
  mounted () {
    this.addListener()
  }
  init() {
    this.inputElArr = [] // 保存所有的input元素对象
    this.inputTimer = null
    if (this.answerShow || this.curSubjectInfo.isAnswer) {
      this.answerArr = this.curSubjectInfo.answer
    } else {
      this.curSubjectInfo.detail.scores.forEach((v:any) => {
        this.answerArr.push('')
      })
    }
    this.dealRichTitle()
  }
  dealRichTitle() {
    let originTitle = this.detail.topic
    this.blankStyle = this.detail.blank_style
    if (this.blankStyle === 'underline') {
      this.matchArr = originTitle.match(/_{3,}/g)
    } else {
      this.matchArr = originTitle.match(/<img\s?\w+[^>]+>/g)
    }
    this.matchArr && this.matchArr.forEach((val:any, index:any) => {
      let template = ''
      // 处理不同填空的形式的渲染
      if (this.blankStyle === 'underline') {
        template = this.getUnderlineTemplate(index)
      } else {
        this.textBoxArr.push('')
        template = this.getTextboxTemplate(index)
      }
      // 获得模板替换
      originTitle = originTitle.replace(val, template)
    })
    this.newTitle = originTitle
  }
  // 下划线的填空
  getUnderlineTemplate(index:any) {
    let value = this.answerArr[index] || ''
    // 计算长度
    let inputTemp = `<input type="text" class='text-input' placeholder='点击答题' data-index="${index}" value="${value}"/>`
    if (this.answerShow) { // 解析
      const fontColorClass = this.checkGroupState(index)
      inputTemp = `<span class='text-input-an ${fontColorClass}'> ${value} </span>`
    }
    return inputTemp
  }
  // 单个输入框的填空
  getTextboxTemplate(index:any) {
    let value = this.answerArr[0] ? (this.answerArr[0][index] || '') : ''
    let inputTemp = `<input class="text-input box-text-input" data-index="${index}" value="${value}" maxlength="1" />`
    if (this.answerShow) { // 解析
      const fontColorClass = this.checkGroupState(0)
      inputTemp = `<input readonly class="box-text-input-an ${fontColorClass}" data-index="${index}" value="${value}" maxlength="1" />`
    }
    return inputTemp
  }
  checkGroupState (index:any) {
    let fontColorClass = 'error-c'
    const curAnswer = this.answerArr[index]
    const temp = this.subType === 'singleblank' ? this.curSubjectInfo.detail.correct_answer : this.curSubjectInfo.detail.correct_answer[index]
    if (Array.isArray(temp)) {
      temp.some(v => {
        // eslint-disable-next-line no-mixed-operators
        if (v.match_mode === 'exact' && v.value === curAnswer || (v.match_mode === 'contain' && v.value === curAnswer)) {
          fontColorClass = 'correct-c'
          // eslint-disable-next-line no-useless-return
          return
        }
      })
    } else {
      // eslint-disable-next-line no-mixed-operators
      if (temp.match_mode === 'exact' && temp.value === curAnswer || (temp.match_mode === 'contain' && temp.value === curAnswer)) {
        fontColorClass = 'correct-c'
      }
    }
    return fontColorClass
  }
  addListener () {
    this.$nextTick(() => {
      const targets = document.getElementsByClassName('text-input')
      let dealKeyboard = (source:any) => {
        const { type } = source
        if (type !== 'focus') {
          document.body.scrollTop = 0
          window.scrollTo(0, 0)
        }
      }
      // 保存对象
      this.inputElArr = [...targets]
      // 遍历所有当前的变量
      this.inputElArr.forEach((target:any) => {
        target.addEventListener('focus', dealKeyboard, false)
        target.addEventListener('blur', dealKeyboard, false)
        target.addEventListener('input', this.dealInput, false)
        // 使用销毁函数钩子事件
        this.$on('hook:beforeDestroy', () => {
          target.removeEventListener('focus', dealKeyboard, false)
          target.removeEventListener('blur', dealKeyboard, false)
          target.removeEventListener('input', this.dealInput, false)
        })
      })
    })
  }
  dealInput (e:any) {
    let { target } = e
    // 处理多次操作
    const index = target.dataset.index
    const value = target.value
    if (this.blankStyle === 'underline') {
      this.answerArr[index] = value
    } else if (this.blankStyle === 'textbox') {
      this.textBoxArr[index] = value
      // console.log(this.textBoxArr)
      let val = ''
      this.textBoxArr.forEach((v:any) => {
        if (v === '') {
          val = `${val} `
        } else {
          val = `${val}${v}`
        }
      })
      this.answerArr[0] = val
    }
    // console.log('xxx 最终传送的值', this.answerArr)
    const curAnswer = this.makeAnswer()
    this.saveAnswer(curAnswer)
  }
  // 检查答案
  checkNeedSave() {
    let nullCount = 0 // 答案未达的总数
    let temp :any = []
    this.answerArr.forEach((v:any) => {
      if (v === '' || /^[ ]+$/.test(v)) {
        nullCount++
        temp.push(null)
      } else {
        temp.push(v)
      }
    })
    this.answerVal = temp
    if (nullCount === this.answerArr.length) { // 答案都没填
      return false
    } else {
      return true
    }
  }
  // 构造答案的数据结构
  makeAnswer() {
    if (this.checkNeedSave()) {
      return {
        material_id: this.curSubjectInfo.childId,
        question_type: this.subType,
        value: this.answerVal
      }
    } else {
      return null
    }
  }
}
