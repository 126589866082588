




























import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import SubjectMixin from '../mixins/subjuectMixin'
import MediaWrap from '../page/components/media-wrap.vue'
import SubjectWrap from '../page/components/subject-wrap.vue'
import * as utils from '../filters/utils'

@Component({
  components: {
    MediaWrap, 
    SubjectWrap
  }
})
export default class ClassWrap extends Mixins(SubjectMixin) {
  [x: string]: any
  selectOptions:any =  []
  answers:any = []
  text:any = ''
  answerVal:any = []
  textArr: any
  get correctAnswer() {
    let temp:any = []
    this.curSubjectInfo.detail.correct_answer.forEach((item:any) => {
      const index = this.selectOptions.findIndex((v: { id: any }) => v.id === item)
      temp.push(this.selectOptions[index].text)
    })
    return temp
  }
  created() {
    this.init()
  }
  init() {
    this.matchAnswer()
    let selectOptions:any = []
    this.curSubjectInfo.detail.content.options.forEach((v:any, i:any) => { // 初始化选项
      selectOptions.push({
        index: i,
        status: v.status,
        id: v.id,
        text: v.text
      })
    })
    this.textArr = this.curSubjectInfo.detail.content.topic.split(/_{3,}/)
    for (let n = 0; n < this.textArr.length - 1; n++) {
      if (this.curSubjectInfo.isAnswer && this.curSubjectInfo.answer[n]) {
        const id = this.curSubjectInfo.answer[n]
        const optIndex = selectOptions.findIndex((v: { id: any }) => v.id === id)
        let obj:any = {
          optIndex,
          id,
          text: selectOptions[optIndex].text
        }
        if (this.answerShow) { // 查看解析，匹配答案
          const correctId = this.curSubjectInfo.detail.correct_answer[n]
          obj.status = selectOptions[optIndex].id === correctId ? 'correct' : 'error'
          selectOptions[optIndex].status = obj.status
        }
        this.answers.push(obj)
      } else {
        this.answers.push({
          optIndex: '',
          id: '',
          text: ''
        })
      }
      this.selectOptions = selectOptions
    }
    this.handleTextArr()
  }
  handleTextArr() {
    let text = ''
    this.textArr.forEach((v:any, i:any) => {
      if (i > 0) {
        const curAnswer = this.answers[i - 1]
        let textColorClass = curAnswer.id === '' ? 'normal-c' : 'active-c'
        const widthClass = curAnswer.id === '' ? 'w-auto' : 'w-170'
        if (curAnswer.status === 'correct') {
          textColorClass = 'correct-c'
        } else if (curAnswer.status === 'error') {
          textColorClass = 'error-c'
        }
        const a = `<a class='blank-seq ${textColorClass}'>${i}</a>`
        text = `${text}<span ref="${i}" class='blank-text ${textColorClass} ${widthClass}'>${curAnswer.id === '' ? a : curAnswer.text}</span>${v}`
      } else {
        text = v
      }
    })
    this.text = text
  }
  onChange(event:any) { // 点击空格，已经选择的选项会被取消
    if (!event.target.attributes.ref || this.answerShow) return
    const index = parseInt(event.target.attributes.ref.value)
    const curItem = this.answers[index - 1]
    if (curItem.id !== '') {
      this.answers[index - 1].id = ''
      this.answers[index - 1].text = ''
      this.selectOptions[curItem.optIndex].status = ''
      this.handleTextArr()
      const curAnswer = this.makeAnswer()
      this.saveAnswer(curAnswer)
    }
  }
  onSelect(item:any) {
    if (this.answerShow) return
    if (item.status === 'active') { // 已经被选择过
      this.selectOptions[item.index].status = ''
      this.answers.some((v:any, i:any) => {
        if (v.id === item.id) {
          this.answers[i].id = ''
          this.answers[i].text = ''
          this.answers[i].optIndex = ''
          return true
        }
      })
    } else {
      this.answers.some((v:any, i:any) => {
        if (v.id === '') { // 空格没有被填满才处理
          this.answers[i].id = item.id
          this.answers[i].text = item.text
          this.answers[i].optIndex = item.index
          this.selectOptions[item.index].status = 'active'
          return true
        } else if (v.id !== '' && this.answers.length - 1 === i) {
          this.$toast('请先提交')
        }
      })
    }
    this.handleTextArr()
    const curAnswer = this.makeAnswer()
    this.saveAnswer(curAnswer)
  }
  // 检查答案
  checkNeedSave() {
    let nullCount = 0 // 答案未达的总数
    let temp:any = []
    this.answers.forEach((v:any) => {
      if (v.id === '') {
        nullCount++
        temp.push(null)
      } else {
        temp.push(v.id)
      }
    })
    this.answerVal = temp
    if (nullCount === this.answers.length) { // 答案都没填
      return false
    } else {
      return true
    }
  }
  // 构造答案的数据结构
  makeAnswer() {
    if (this.checkNeedSave()) {
      return {
        material_id: this.curSubjectInfo.childId,
        question_type: this.subType,
        value: this.answerVal
      }
    } else {
      return null
    }
  }
}
