


































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import MediaWrap from '../page/components/media-wrap.vue'
import SubjectWrap from '../page/components/subject-wrap.vue'
// import MediaUpload from '@/v-components/smart/media-upload'
import SubjectMixin from '../mixins/subjuectMixin'
import IndexMixin from '../mixins/indexMixin'
import {sortBy} from 'lodash'
import {getSubject} from '../api/index'

@Component({
  components: {
    SubjectWrap, 
    MediaWrap, 
    // MediaUpload
  }
})
export default class ClassWrap extends Mixins(SubjectMixin,IndexMixin) {
  @Prop() answerCardDetail: any
  answerInfo: any = ''
  answerText:any = ''

  created() {
    this.init()
  }
  init() {
    if (this.curSubjectInfo.isAnswer) {
      this.answerInfo = this.curSubjectInfo.answer
      if (this.curSubjectInfo.detail.remark) {
        this.dealAnswerText()
      } else { // 练习模式的时候，需要重新去请求接口拿到匹配的信息
        this.getSubject()
      }
    }
  }
  // 获取题目
  getSubject() {
    getSubject({questionId: this.curSubjectInfo.id}).then(res => {
      this.handleSubject(res.data, this.answerCardDetail.answer_card)
    })
  }
  dealAnswerText() {
    const correctAnswer = this.curSubjectInfo.detail.correct_answer[0]
    let str = ''
    let arr:any = []
    const deleteArr = this.curSubjectInfo.detail.remark.delete || []
    const insertArr = this.curSubjectInfo.detail.remark.insert || []
    const replaceArr = this.curSubjectInfo.detail.remark.replace || []
    deleteArr.forEach((v:any) => {
      arr.push({
        type: 'delete',
        startIndex: v[0],
        endIndex: v[1]
      })
    })
    insertArr.forEach((v:any) => {
      arr.push({
        type: 'insert',
        startIndex: v[0],
        endIndex: v[1],
        oStartIndex: v[2],
        oEndIndex: v[3]
      })
    })
    replaceArr.forEach((v:any) => {
      arr.push({
        type: 'replace',
        startIndex: v[0],
        endIndex: v[1],
        oStartIndex: v[2],
        oEndIndex: v[3]
      })
    })
    let newArr = sortBy(arr, (v:any) => v.startIndex)
    newArr.forEach((v:any, i:any) => {
      if (i === 0 && v.startIndex > 0) {
        str = this.answerInfo.substring(0, v.startIndex)
      }
      switch (v.type) {
        case 'delete':
          str = `${str}<span class="line-through">${this.answerInfo.substring(v.startIndex, v.endIndex)}</span>`
          break
        case 'insert':
          str = `${str}<span class="underline">${correctAnswer.substring(v.oStartIndex, v.oEndIndex)}</span>`
          break
        case 'replace':
          str = `${str}<span class="error">${this.answerInfo.substring(v.startIndex, v.endIndex)}</span><span class="correct">(${correctAnswer.substring(v.oStartIndex, v.oEndIndex)})</span>`
          break
      }
      if (newArr.length === i + 1 && newArr.length === v.endIndex) {
        str = `${str}${this.answerInfo.substring(v.endIndex, this.answerInfo.length - 1)}`
      } else if (newArr.length > i + 1) {
        str = `${str}${this.answerInfo.substring(v.endIndex, newArr[i + 1].startIndex)}`
      }
    })
    this.answerText = str
  }
  // 检查答案
  checkNeedSave(value:any) {
    return value !== ''
  }
  // 构造答案的数据结构
  makeAnswer(value:any) {
    if (this.checkNeedSave(value)) {
      return {
        material_id: this.curSubjectInfo.childId,
        question_type: this.subType,
        value
      }
    } else {
      return null
    }
  }
  getAnswerData() {
    const curAnswer = this.makeAnswer(this.answerInfo)
    this.saveAnswer(curAnswer)
  }
}
