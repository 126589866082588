






































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import SubjectWrap from '../page/components/subject-wrap.vue'
import SubjectMixin from '../mixins/subjuectMixin'
import IndexMixin from '../mixins/indexMixin'

@Component({
  components: {
    SubjectWrap
  }
})
export default class ClassWrap extends Mixins(SubjectMixin,IndexMixin) {
  @Prop() answerCardDetail: any
  answer: string = ''
  answerInfo: string = ''
  answerText: any[] = []
  content: any = ''
  maxLength: number = 100
  record_count: number = 0

  get analysisShow() {
    return this.$route.query.renderType === 'analysis' || (this.$route.query.examType === 'practice' && this.curSubjectInfo.isAnswer)
  }
  
  get diffChars() {
    const diffArray = [];
    const answerLength = this.content ? this.content.length : 0
    const answerTextLength = this.answerInfo ? this.answerInfo.length : 0
    const maxLength = Math.max(answerLength, answerTextLength);

    for (let i = 0; i < maxLength; i++) {
      const fixedValue = this.content.charAt(i) || ' ';
      const standardValue = this.answerInfo.charAt(i) || ' ';

      diffArray.push({
        value: fixedValue,
        isMismatch: fixedValue !== standardValue
      });
    }
    return diffArray;
  }
  created() {
    this.init()
    this.content = this.curSubjectInfo.answer
  }
  init() {
    this.answerInfo = this.curSubjectInfo.detail.content.answer_text
    this.answerText = this.answerInfo.split('').map(item => {
      return {
        text: item,
        type: 1,
      }
    })
  }
  // 检查答案
  checkNeedSave(value:any) {
    return value !== ''
  }
  // 构造答案的数据结构
  makeAnswer(value:any) {
    if (this.checkNeedSave(value)) {
      return {
        material_id: this.curSubjectInfo.childId,
        question_type: this.subType,
        record_count: this.record_count,
        value
      }
    } else {
      return null
    }
  }

  getAnswerData(e:string) {
    this.maxLength = this.curSubjectInfo.detail.content.text_count
    const arr = e.split('')
    if (this.answer.length > this.maxLength) {
      this.answer = this.answer.slice(0,this.maxLength)
      return
    }
    let count = 0
    this.answerText = this.answerText.map((item, index) => {
      if (index < arr.length ) {
        if (item.text === arr[index]) {
          count = count + 1
          return {
            text: item.text,
            type: 2
          }
        } else {
          return {
            text: item.text,
            type: 3,
          }
        }  
      } else {
        return {
          text: item.text,
          type:1
        }
      }
    });
    this.record_count = count;
    this.content = this.answer
    const curAnswer = this.makeAnswer(this.answer)
    this.saveAnswer(curAnswer)
  }
}
