








import { Component, Vue, Watch,Prop,Mixins } from 'vue-property-decorator'


@Component({
  components: {
    
  }
})

export default class CourseAudioClass extends  Vue {
  @Prop() accept!: String
  @Prop({default() { return false }}) multiple!: Boolean
  handleClick() {
    (this.$refs.fileSelectInput as any).click()
  }
  handleChange(ev:any) {
    console.log(ev, '有没有进到这里');
    
    const files = ev.target.files
    if (files && files.length > 0) {
      console.log(1234);
      
      this.$emit('onFileSelect', files)
    }
  }
  clearFile() {
    (this.$refs.fileSelectInput as any).value = ''
  }
}
