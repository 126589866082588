

































import { Component, Vue, Watch, Prop, Mixins } from 'vue-property-decorator'
import commonMixin from '../../mixins/commonMixin'
import { eventBus } from '../../filters/event-bus'
import { counterTimeBySec } from '@/modules/exam/filters/utils'
// import * as utils from '@/filters/utils'


@Component({
  components: {
    
  }
})

export default class ClassWrap extends Mixins(commonMixin) {
  @Prop({default: false}) timerShow!: Boolean
  @Prop({default() { return {} }}) answerCardDetail!: any
  @Prop({default() { return {} }}) examDetail!: any
  @Prop() curAnswer!: any
  precent:any =  20
  timer:any =  null
  countdownText:any =  ''
  remainTime:any =  0 // 考试剩余时间


  get isFirst() { // 是否是第一题
    return this.allCardList[0] && this.allCardList[0].childId === this.curSubjectInfo.childId
  }
  get isLast() { // 是否是最后一题
    const last = this.allCardList[this.allCardList.length - 1]
    return last && last.childId === this.curSubjectInfo.childId
  }
  get curIdex() { // 当前所在题目的序号（包含了题目说明、阅读理解中的各种小题）
    return this.allCardList.findIndex((item:any) => item.childId === this.curSubjectInfo.childId)
  }
  get isAnswer() { // 当前题目是否已经答题
    return this.curAnswer || this.curSubjectInfo.isAnswer
  }
  get isSectionFreeStudy() {
    // 部分试学
    return !this.examDetail.has_subscribed && this.examDetail.trial_question_count > 0
  }

  mounted() {
    // if (this.examDetail.latest_paper) {
    //   this.remainTime = this.examDetail.latest_paper.remain_time
    // } else {
    //   this.remainTime = this.examDetail.time_limit * 60
    // }
    // if (this.remainTime && this.renderType === 'exam') { // 考试时间到了
    //   // this.countdown()
    // } else if (this.renderType === 'exam') {
    //   if (this.isSectionFreeStudy) {
    //     eventBus.$emit('header.showDialog')
    //   } else {
    //     // this.$refs.interruptDialog.open()
    //   }
    // }
  }
  destroyed() {
    this.cancel()
  }

  // // 考试倒计时
  // countdown() {
  //   if (this.remainTime > 0) {
  //     this.timer = setInterval(() => {
  //       const count = counterTimeBySec(this.remainTime)
  //       if (!count.valid) {
  //         if (this.isSectionFreeStudy) {
  //           eventBus.$emit('header.showDialog')
  //         } else {
  //           // this.$refs.interruptDialog.open()
  //         }
  //         this.cancel()
  //       } else {
  //         if (Number(count.day)) {
  //           this.countdownText = `${count.day}日${count.hour}时${count.min}分${count.sec}秒`
  //         } else {
  //           this.countdownText = `${count.hour}时${count.min}分${count.sec}秒`
  //         }
  //         this.remainTime--
  //       }
  //     }, 1000)
  //   }
  // }

  // 时间已经到，自动交卷
  autoSubmitExam() {
    eventBus.$emit('index.autoSubmitExam')
  }
  // 手动交卷需检查答题情况
  submitExam() {
    eventBus.$emit('index.submitExam')
  }
  cancel() {
    clearInterval(this.timer)
  }
  pre() {
    if (this.isFirst) return
    this.changIndex(this.curIdex - 1)
  }
  next() {
    if (this.isLast) return
    // console.log(this.curIdex, 'this.curIdex');
    this.changIndex(this.curIdex + 1)
  }
  // 切换题目
  changIndex(nextIndex:any) {
    const nextSub = this.allCardList[nextIndex] // 下一道题
    const index = this.examList.findIndex((item:any) => {
      return item.childId === nextSub.childId
    })
    if (index === -1) { // 题目列表中没有该题，需要去请求接口
      eventBus.$emit('index.changeSubject', nextSub)
    } else {
      eventBus.$emit('index.exam.submitRecord', this.examList[index])
    }
  }
  // 练习模式提交答案
  submitRecord() {
    eventBus.$emit('index.practice.submitRecord')
  }
  back() {
    this.$router.replace({ name: 'examAnalysis', params: {id: this.$route.params.id} })
  }
}
