













import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import commonMixin from '../../mixins/commonMixin'
import BlankSubject from '../../topic-type/blank.vue'
import NormalSubject from '../../topic-type/normal.vue'
import SpokenSubject from '../../topic-type/spoken.vue'
import SelectBlankSubject from '../../topic-type/select-blank.vue'
import SortSubject from '../../topic-type/sort.vue'
import EssaySubject from '../../topic-type/essay.vue'
import Combination from '../../topic-type/combination.vue'
import ListeningSubject from '../../topic-type/listening.vue'
import TypeWriting from '../../topic-type/typewriting.vue'



@Component({
  components: {
    BlankSubject,
    NormalSubject,
    SpokenSubject,
    SelectBlankSubject,
    SortSubject,
    EssaySubject,
    Combination,
    ListeningSubject,
    TypeWriting
  }
})
export default class ClassWrap extends Mixins(commonMixin) {
  @Prop() answerCardDetail!: any
  mapComponentType(type :any) {
    const map:any = {
      radio: 'normal-subject',      //单选题
      judge: 'normal-subject',      //判断题
      checkbox: 'normal-subject',   //多选题
      singleblank: 'blank-subject', //填空题
      mulitblank: 'blank-subject',  //多元选择
      englishspoken: 'spoken-subject', //英语口语能力
      mandarin: 'spoken-subject',      //普通话口语
      optionblank: 'select-blank-subject',//选词填空
      sort: 'sort-subject',         //排序
      essay: 'essay-subject',       //问答题
      'rich_text': 'description',
      'combination': 'combination', //阅读理解
      'listening': 'listening-subject',//听写
      'typewriting':'type-writing'  //打字
    }
    return map[type]
  }
}
