




















import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import MediaWrap from './media-wrap.vue'
import AnalysisWrap from './analysis.vue'


@Component({
  components: {
    MediaWrap, 
    AnalysisWrap
  }
})
export default class ClassWrap extends Vue {
  @Prop({default: true}) showTitle!: Boolean
  @Prop({default: () => {
    return {}
  }}) subjectinfo!: any
  @Prop() subType!: String
  @Prop({default: true}) seqTextShow!: Boolean
  subjectType:any =  {
    radio: '单选题',
    checkbox: '多选题',
    judge: '判断题',
    essay: '问答题',
    mandarin: '普通话',
    englishspoken: '英语口语能力',
    sort: '排序题',
    singleblank: '填空题',
    mulitblank: '多元填空',
    optionblank: '选词填空',
    combination: '阅读理解',
    listening: '听写题',
    typewriting: '打字题'
  }
  richSrc: string = ''
  prompt: string = '温馨提示：题干中文字蓝色表示输入正确，红色表示输入错误，在提交之前可以反复修改。'

  get analysisShow() {
    return this.$route.query.renderType === 'analysis' || (this.$route.query.examType === 'practice' && this.subjectinfo.isAnswer)
 
  }

  get examTitle() {
    // console.log('this.$store.state.exam.examDetail',this.$store.state.exam.examDetail)
    return this.$store.state.exam.examDetail
  }

  created() {
    this.richSrc = this.subType === 'combination' ? this.subjectinfo.detail.title : this.subjectinfo.detail.content.topic
    // console.log( this.richSrc.indexOf('src'), 'indexof');
    if(this.richSrc.indexOf('src') != -1){
      // 在指定字符串后面添加指定字符串
      var strArr = this.richSrc.split('src');
      strArr.forEach((item: string, index: number) => {
        index !== strArr.length - 1 ? strArr[index] = item + 'style="width: 800px" ' : strArr[index] = item
      });
      this.richSrc = strArr.join('src')
    }
   
    
    

    // // strArr.splice(oldStr.indexOf(afterWhich) + afterWhich.length, 0, addItem);
    // // return strArr.join('');
    // this.addStr(src, '')
  }
  
  // addStr(oldStr:any, addItem:any, afterWhich:any) {
  //   // 在指定字符串后面添加指定字符串
  //   var strArr = oldStr.split('');
  //   strArr.splice(oldStr.indexOf(afterWhich) + afterWhich.length, 0, addItem);
  //   return strArr.join('');
  // }
}
