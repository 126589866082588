





























import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import MediaWrap from '../page/components/video-media-wrap.vue'
import SubjectWrap from '../page/components/subject-wrap.vue'
import SubjectMixin from '../mixins/subjuectMixin'

@Component({
  filters: {
    answerText(answers:any, options:any) {
      let temp :any = []
      // console.log(options, 'options')
      options && options.forEach((item:any) => {
        if (answers.includes(item.id)) {
          temp.push(item.selectTip)
        }
      })
      return temp.join('')
    }
  },
  components: {
    SubjectWrap, 
    MediaWrap
  }
})
export default class ClassWrap extends Mixins(SubjectMixin) {
  created() {
    this.init()
    // console.log(this.curSubjectInfo, '选择题')
  }

  init() {
    this.matchAnswer()
  }
  // 选择答案后，给题目选项增加active标识,并且暂存答案
  addSelectActiveFlag(selectIndex:any) {
    // console.log(selectIndex, 'selectIndex');
    
    let answers = []
    switch (this.subType) {
      case 'judge':
      case 'radio':
        answers = this.dealRadioAnswer(selectIndex)
        // console.log(answers, 'answers');
        
        break
      case 'checkbox':
        answers = this.dealCheckboxAnswer(selectIndex)
        break
    }
    const curAnswer = this.makeAnswer(answers)
    // console.log(curAnswer, 'curAnswer');
    
    this.saveAnswer(curAnswer)
    this.$store.commit('SET_CUR_SUBJECY', JSON.parse(JSON.stringify(this.curSubjectInfo)))
    this.$store.dispatch('setSubjectItem', this.curSubjectInfo)
  }
  // 处理单选的答案
  dealRadioAnswer(selectIndex:any) {
    let answers:any = []
    this.curSubjectInfo.detail.content.options.map((item:any, index:any) => {
      if (index === selectIndex) {
        item.status = 'active'
        answers.push(item.id)
      } else {
        item.status = ''
      }
      return item
    })
    return answers
  }
  // 处理多选的答案
  dealCheckboxAnswer(selectIndex:any) {
    let answers:any = []
    this.curSubjectInfo.detail.content.options.map((item:any, index:any) => {
      if (index === selectIndex) {
        item.status = item.status === 'active' ? '' : 'active'
      }
      if (item.status === 'active') answers.push(item.id)
      return item
    })
    return answers
  }
  selectTouchEnd(optIndex:any) {   
    if (this.answerShow) return
    this.addSelectActiveFlag(optIndex)
  }
  makeAnswer(value:any) {
    return {
      material_id: this.curSubjectInfo.childId,
      question_type: this.subType,
      value
    }
  }
}
